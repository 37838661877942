// This file was automatically generated from concierge.dining.landing.soy.
// Please don't edit this file by hand.

if (typeof Nightloop == 'undefined') { var Nightloop = {}; }
if (typeof Nightloop.Templates == 'undefined') { Nightloop.Templates = {}; }
if (typeof Nightloop.Templates.Concierge == 'undefined') { Nightloop.Templates.Concierge = {}; }
if (typeof Nightloop.Templates.Concierge.Dining == 'undefined') { Nightloop.Templates.Concierge.Dining = {}; }


Nightloop.Templates.Concierge.Dining.Landing = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Templates.Concierge.Layout(soy.$$augmentData(opt_data, {DynamicTemplate: 'Nightloop.Templates.Concierge.Dining.LandingBit', full_width: true}), output);
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Concierge.Dining.LandingBit = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<div id="search-container"><div id="left-filters" class="float-left"><div><div id="header">Make a Dining Reservation</div><div id="search-box"><form id="search-form" class="ajaxify" method="GET" action="', soy.$$escapeHtml(opt_data.concierge.base_url), '/dining/search/', soy.$$escapeHtml(opt_data.content.reservation_id), '"><div class="search-input"><input id="search-query-input" type="text" name="search_query" placeholder="Name or keywords (optional)" value=""/></div><div class="search-input"><select name=\'', soy.$$escapeHtml(opt_data.content.form.fields.municipality.name), '\'>');
  var muniList11049 = opt_data.content.municipalities;
  var muniListLen11049 = muniList11049.length;
  for (var muniIndex11049 = 0; muniIndex11049 < muniListLen11049; muniIndex11049++) {
    var muniData11049 = muniList11049[muniIndex11049];
    output.append('<option ', (opt_data.content.muni_id == muniData11049[0]) ? 'selected' : '', ' value="', soy.$$escapeHtml(muniData11049[0]), '">', soy.$$escapeHtml(muniData11049[1]), ', ', soy.$$escapeHtml(muniData11049[2]), '</option>');
  }
  output.append('</select></div><div id="search-date-select" class="search-input"><div id="search-date-display">Tonight</div><input id="search-date-input" type="hidden" class="datepicker sans" name="date-localized" value="', soy.$$escapeHtml(opt_data.content.date_formatted), '"><input id="search-date-input-submit" type="hidden" name="', soy.$$escapeHtml(opt_data.content.form.fields.date.name), '" value="', soy.$$escapeHtml(opt_data.content.date_formatted_default), '"><div class="float-end"></div></div><div class="search-input"><select id="home-partysize-select" name="', soy.$$escapeHtml(opt_data.content.form.fields.max_guests.name), '">');
  var choiceList11071 = opt_data.content.form.fields.max_guests.choices;
  var choiceListLen11071 = choiceList11071.length;
  for (var choiceIndex11071 = 0; choiceIndex11071 < choiceListLen11071; choiceIndex11071++) {
    var choiceData11071 = choiceList11071[choiceIndex11071];
    output.append('<option class="number" value="', soy.$$escapeHtml(choiceData11071[0]), '" ', (choiceData11071[0] == opt_data.content.form.fields.max_guests.value) ? 'selected="selected"' : '', '>', soy.$$escapeHtml(choiceData11071[1]), '</option>');
  }
  output.append('</select></div><div class="search-input last"><select id="home-timeslot-select" name="', soy.$$escapeHtml(opt_data.content.form.fields.time_slot.name), '">');
  var choiceList11085 = opt_data.content.time_options;
  var choiceListLen11085 = choiceList11085.length;
  for (var choiceIndex11085 = 0; choiceIndex11085 < choiceListLen11085; choiceIndex11085++) {
    var choiceData11085 = choiceList11085[choiceIndex11085];
    output.append('<option class="number" value="', soy.$$escapeHtml(choiceData11085), '" ', (choiceData11085 == opt_data.content.form.fields.time_slot.value) ? 'selected="selected"' : '', '>', soy.$$escapeHtml(choiceData11085), '</option>');
  }
  output.append('</select></div><div id="search-submit" class="search-button">SEARCH</div></form></div></div></div><div id="search-results" class="float-left"><div id="results"><div id="overlay-container" class="no-display"><div id="overlay" class="landing"><!--- <img src="', soy.$$escapeHtml(opt_data.MEDIA_URL), 'images/spinner.gif" alt="Loading..." /> ---></div></div><div id="instructions"><div class="float-left arrow-left"><img src="', soy.$$escapeHtml(opt_data.MEDIA_URL), 'images/left-gray-thin-arrow.png" /></div><div class="float-left">Search exclusive reservations, <a class="ajaxify" href="', soy.$$escapeHtml(opt_data.concierge.base_url), '/directory?venue_class=DINING">or browse all dining venues</a>.</div><div class="float-end"></div></div></div></div><div class="float-end"></div></div><script type="text/javascript">$(document).ready(function() {Pmp.Concierge.Dining.Search.initialize( \'', soy.$$escapeHtml(opt_data.concierge.base_url), '\', \'', soy.$$escapeHtml(opt_data.content.reservation_id), '\', \'', soy.$$escapeHtml(opt_data.content.muni_today_date), '\', \'', soy.$$escapeHtml(opt_data.content.date_formatted_default), '\', "', soy.$$escapeHtml(opt_data.concierge.locale), '" );});<\/script>');
  return opt_sb ? '' : output.toString();
};
